<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Claim Payment Management</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-switch label="Select individually" class="pt-7 mr-2" v-model="showSelect"></v-switch>
      <v-switch
        class="pt-7 mr-2"
        :label="$i18n.translate('Follow Up') + '?'"
        v-model="followUp"
        @change="onSubmit"
      ></v-switch>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-2"
          v-model="search"
          :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Claims')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn class="mr-2" small fab v-on="{ ...onTooltip }" @click="onDownloadClaims">
            <v-icon>mdi-arrow-down-bold-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>Download and Mark Credit Memo Claims</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn class="mr-2" small fab v-on="{ ...onTooltip }" @click="dialogTemplateToUpload = true">
            <v-icon>mdi-arrow-up-bold-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>Upload Credit Memo Claims</span>
      </v-tooltip>
      <v-tooltip bottom v-if="hasFilters">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn class="mr-2" small fab v-on="{ ...onTooltip }" @click="onClearSearch">
            <v-icon>mdi-magnify-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Clear Search") }}</span>
      </v-tooltip>
      <template v-slot:extension>
        <v-tabs v-model="selectedClaimTypeCountIndex" v-if="claimTypeCounts">
          <v-tab
            v-for="(claimTypeCount, i) in claimTypeCounts"
            :key="i"
            @click="onSelectClaimTypeCount(claimTypeCount)"
          >
            <v-badge color="primary" :content="claimTypeCount.count" v-if="claimTypeCount.claimTypeId">
              {{ claimTypeCount.claimTypeName }}
            </v-badge>
            <span v-else>
              {{ claimTypeCount.claimTypeName }}
            </span>
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="3">
            <v-list dense v-if="selectedClaimTypeCount && selectedClaimTypeCount.claimTypeId">
              <v-subheader>Filter by Stage</v-subheader>
              <v-list-item-group v-model="selectedClaimStageCountIndex" color="primary">
                <v-list-item
                  v-for="(claimStageCount, i) in claimStageCounts"
                  :key="i"
                  @click="onSelectClaimStageCount(claimStageCount)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="claimStageCount.claimStageName"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="claimStageCount.count">
                    <v-list-item-action-text>
                      <v-badge left color="primary">
                        <span slot="badge">{{ claimStageCount.count }}</span>
                      </v-badge>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
              <v-subheader>Filter by Award Vehicle</v-subheader>
              <v-list-item-group v-model="selectedAwardVehicleCountIndex" color="primary">
                <v-list-item
                  v-for="(awardVehicle, i) in awardVehicleCounts"
                  :key="i"
                  @click="onSelectAwardVehicleCount(awardVehicle)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="awardVehicle.awardVehicleName"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="awardVehicle.count">
                    <v-list-item-action-text>
                      <v-badge left color="primary">
                        <span slot="badge">{{ awardVehicle.count }}</span>
                      </v-badge>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
              <v-subheader>Filter by Company</v-subheader>
              <v-list-item-group v-model="selectedOrganizationCountIndex" color="primary">
                <v-list-item
                  v-for="(organizationCount, i) in organizationCounts"
                  :key="i"
                  @click="onSelectOrganizationCount(organizationCount)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="organizationCount.organizationName"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="organizationCount.count">
                    <v-list-item-action-text>
                      <v-badge left color="primary">
                        <span slot="badge">{{ organizationCount.count }}</span>
                      </v-badge>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-list dense v-else>
              <v-subheader>First select a Claim Type</v-subheader>
            </v-list>
          </v-col>
          <v-col cols="9">
            <ApiError :errors="errors"></ApiError>
            <span
              v-if="
                selectedClaimTypeCount ||
                  selectedOrganizationCount ||
                  selectedClaimStageCount ||
                  selectedAwardVehicleCount
              "
            >
              Showing:
              <v-chip close @click:close="onClearClaimTypeCount" v-if="selectedClaimTypeCount">
                {{ selectedClaimTypeCount.claimTypeName }}
              </v-chip>
              <v-chip close @click:close="onClearClaimStageCount" v-if="selectedClaimStageCount">
                {{ selectedClaimStageCount.claimStageName }}
              </v-chip>
              <v-chip close @click:close="onClearOrganizationCount" v-if="selectedOrganizationCount">
                {{ selectedOrganizationCount.organizationName }}
              </v-chip>
            </span>
            <span v-if="selectedOrganizationCount" class="ml-5">
              <v-btn color="primary" @click="onSelectMultiPayment">Bulk Pay Selected</v-btn>
            </span>
            <v-chip close @click:close="onClearAwardVehicleCount" v-if="selectedAwardVehicleCount">
              {{ selectedAwardVehicleCount.awardVehicleName }}
            </v-chip>

            <v-data-table
              v-if="render"
              v-model="selectedClaims"
              :items="items"
              :headers="headers"
              :server-items-length="total"
              :options.sync="options"
              :loading="loading"
              :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
              :show-select="selectedOrganizationCount != undefined || showSelect"
              :expanded.sync="expanded"
              show-expand
              single-expand
              @item-expanded="fetchClaimProducts"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-card>
                    <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
                    <v-data-table
                      class="mt-5 mb-5 compact-table"
                      :calculate-widths="true"
                      v-else
                      hide-default-footer
                      :headers="claimProductHeader"
                      :items="expandedClaimProducts[item.id]"
                    >
                      <template v-slot:item.referenceNumber="{ item }">
                        <span v-if="item.payouts && item.payouts.length == 1">
                          {{ item.payouts[0].referenceNumber }}
                        </span>

                        <ol v-if="item.payouts && item.payouts.length > 1">
                          <li v-for="(claimProductPayout, i) in item.payouts" :key="i" class="text-no-wrap">
                            <span>
                              {{ item.payouts[i].referenceNumber }}
                            </span>
                          </li>
                        </ol>
                      </template>
                    </v-data-table>
                    <v-card-actions v-if="showRemoveReferenceNumberBtn(item.id)">
                      <v-btn color="blue-grey" class="ma-2 white--text" @click="removeReferenceNumber(item.id)">
                        Remove Reference number
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </td>
              </template>
              <template v-slot:item.pay="{ item }">
                <v-btn color="primary" @click="onSelectSinglePayment(item)">Pay</v-btn>
              </template>
              <template v-slot:item.claimKey="{ item }">
                <router-link :to="toClaimDetailManagement(item)">
                  {{ item.claimKey }}
                </router-link>
              </template>
              <template v-slot:item.participant.user.firstName="{ item }">
                {{ item.participant.user.fullName }}
              </template>
              <template v-slot:item.calculatedTotalClaimAmount="{ item }">
                {{ item.calculatedCalculatedTotalClaimAmount | toNumber(2, selectedProgram) }}
                {{
                  item.calculatedCalculatedTotalClaimAmountCurrency
                    ? item.calculatedCalculatedTotalClaimAmountCurrency
                    : ""
                }}
              </template>

              <template v-slot:item.updatedDate="{ item }">
                {{ item.updatedDate | formatDateFromNow }}
              </template>
              <template v-slot:item.createdDate="{ item }">
                {{ item.createdDate | formatDateFromNow }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-tooltip bottom v-if="item.followUp && item.followUp.length > 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">
                      mdi-tag-text-outline
                    </v-icon>
                  </template>
                  <span>{{ item.followUp }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.claimStage.name="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"> {{ item.claimStage | formatStage(item) }} </span>
                  </template>
                  <span>{{ item.claimStage.description }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="dialogSinglePayment" v-if="claimSinglePayment">
        <v-form @submit.prevent="onSinglePayment" v-model="validSinglePayment">
          <v-card :loading="loading">
            <v-card-title>
              <span class="headline">Schedule Payment</span>
            </v-card-title>
            <v-card-text>
              <ApiError :errors="errors"></ApiError>
              <p>
                <b>Claim: {{ claimSinglePayment.claimKey }}</b>
              </p>
              <p>The following payments will be scheduled for this claim:</p>
              <v-data-table :items="awardVehicleCounts" :headers="awardVehicleCountHeaders" :loading="loading">
                <template v-slot:item.awardVehicle="{ item }">
                  {{ item.awardVehicle ? item.awardVehicle.name : "" }}
                </template>
                <template v-slot:item.sum="{ item }">
                  <span v-if="item.sum">
                    {{ item.calculatedSum | toNumber(2, selectedProgram) }}
                    {{ item.calculatedSumCurrency ? item.calculatedSumCurrency : "" }}
                  </span>
                  <span v-else-if="item.uniqueProductCount">
                    <span v-if="item.uniqueProductCount == 1">{{ item.maxProduct }}</span>
                    <span v-else>{{ item.uniqueProductCount }} Various Products</span>
                  </span>
                </template>
                <template v-slot:item.transactionInfo="{ item }">
                  <span
                    v-if="
                      item.awardVehicle &&
                        item.awardVehicle.awardVehicleType &&
                        item.awardVehicle.awardVehicleType.name == 'CREDIT_MEMO'
                    "
                  >
                    <v-text-field
                      label="Credit Memo #"
                      v-model="formSinglePayment.creditMemoNumber"
                      :rules="rules.creditMemoNumber"
                      class="required"
                    ></v-text-field>
                    <DateTimePickerField
                      label="Credit Memo Date"
                      v-model="formSinglePayment.creditMemoDate"
                      :rules="rules.creditMemoDate"
                      class="required"
                      format="MM/DD/YYYY"
                      defaultTime="00:00"
                      onlyDate
                    ></DateTimePickerField>
                  </span>
                  <span
                    v-if="
                      item.awardVehicle &&
                        item.awardVehicle.awardVehicleType &&
                        item.awardVehicle.awardVehicleType.name == 'MANUAL'
                    "
                  >
                    <v-text-field label="Ach Reference #" v-model="formSinglePayment.achReferenceNumber"></v-text-field>
                    <DateTimePickerField
                      label="Ach Date"
                      v-model="formSinglePayment.achDate"
                      format="MM/DD/YYYY"
                      defaultTime="00:00"
                      onlyDate
                    ></DateTimePickerField>

                    <v-text-field label="Check #" v-model="formSinglePayment.checkNumber"></v-text-field>
                    <DateTimePickerField
                      label="Check Date"
                      v-model="formSinglePayment.checkDate"
                      format="MM/DD/YYYY"
                      defaultTime="00:00"
                      onlyDate
                    ></DateTimePickerField>

                    <v-text-field label="Credit Memo #" v-model="formSinglePayment.creditMemoNumber"></v-text-field>
                    <DateTimePickerField
                      label="Credit Memo Date"
                      v-model="formSinglePayment.creditMemoDate"
                      format="MM/DD/YYYY"
                      defaultTime="00:00"
                      onlyDate
                    ></DateTimePickerField>
                  </span>

                  <v-col cols="6">
                    <v-text-field
                      v-if="
                        item.awardVehicle &&
                          item.awardVehicle.awardVehicleType &&
                          item.awardVehicle.awardVehicleType.name == 'CHECKISSUING_CHECK' &&
                          claimSinglePayment
                      "
                      v-model="formSinglePayment.checkMemo"
                      label="Check Memo"
                      :hint="'If left blank, the memo will read “Claim #' + claimSinglePayment.id + ' Payout”'"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </template>
                <template v-slot:item.count="{ item }">
                  {{ item.count }}
                  <span v-if="item.sumProductQuantity && item.sumProductQuantity > 0"
                    >({{ item.sumProductQuantity }} products)</span
                  >
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogSinglePayment = false">{{
                $i18n.translate("Cancel")
              }}</v-btn>
              <v-btn color="blue darken-1" text :disabled="!validSinglePayment" @click="onSinglePayment"
                >Schedule Payment</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="dialogMultiPayment">
        <v-form @submit.prevent="onMultiPayment" v-model="validMultiPayment">
          <v-card :loading="loading">
            <v-card-title>
              <span class="headline">Schedule Payment</span>
            </v-card-title>
            <v-card-text>
              <ApiError :errors="errors"></ApiError>
              <p>
                <b>Claims Selected:</b>
                <span v-for="(claimMultiPayment, i) in selectedClaims" :key="i">
                  {{ claimMultiPayment.claimKey }}
                </span>
              </p>
              <p>The following payments will be scheduled for these claims:</p>
              <v-data-table :items="awardVehicleCounts" :headers="awardVehicleCountHeaders" :loading="loading">
                <template v-slot:item.awardVehicle="{ item }">
                  {{ item.awardVehicle ? item.awardVehicle.name : "" }}
                </template>
                <template v-slot:item.sum="{ item }">
                  <span v-if="!item.products">
                    {{ item.calculatedSum | toNumber(2, selectedProgram) }}
                    {{ item.calculatedSumCurrency ? item.calculatedSumCurrency : "" }}
                  </span>
                  <span v-else> {{ item.products }} </span>
                </template>
                <template v-slot:item.transactionInfo="{ item }">
                  <span
                    v-if="
                      item.awardVehicle &&
                        item.awardVehicle.awardVehicleType &&
                        item.awardVehicle.awardVehicleType.name == 'CREDIT_MEMO'
                    "
                  >
                    <v-text-field
                      label="Credit Memo #"
                      v-model="formMultiPayment.creditMemoNumber"
                      :rules="rules.creditMemoNumber"
                      class="required"
                    ></v-text-field>
                    <DateTimePickerField
                      label="Credit Memo Date"
                      v-model="formMultiPayment.creditMemoDate"
                      :rules="rules.creditMemoDate"
                      class="required"
                      format="MM/DD/YYYY"
                      defaultTime="00:00"
                      onlyDate
                    ></DateTimePickerField>
                  </span>
                  <span
                    v-if="
                      item.awardVehicle &&
                        item.awardVehicle.awardVehicleType &&
                        item.awardVehicle.awardVehicleType.name == 'MANUAL'
                    "
                  >
                    <v-text-field label="Ach Reference #" v-model="formMultiPayment.achReferenceNumber"></v-text-field>
                    <DateTimePickerField
                      label="Ach Date"
                      v-model="formMultiPayment.achDate"
                      format="MM/DD/YYYY"
                      defaultTime="00:00"
                      onlyDate
                    ></DateTimePickerField>

                    <v-text-field label="Check #" v-model="formMultiPayment.checkNumber"></v-text-field>
                    <DateTimePickerField
                      label="Check Date"
                      v-model="formMultiPayment.checkDate"
                      format="MM/DD/YYYY"
                      defaultTime="00:00"
                      onlyDate
                    ></DateTimePickerField>

                    <v-text-field label="Credit Memo #" v-model="formMultiPayment.creditMemoNumber"></v-text-field>
                    <DateTimePickerField
                      label="Credit Memo Date"
                      v-model="formMultiPayment.creditMemoDate"
                      format="MM/DD/YYYY"
                      defaultTime="00:00"
                      onlyDate
                    ></DateTimePickerField>
                  </span>
                </template>
                <template v-slot:item.count="{ item }">
                  {{ item.count }}
                  <span
                    v-if="
                      item.awardVehicle &&
                        item.awardVehicle.awardVehicleType &&
                        item.awardVehicle.awardVehicleType.name == 'GENERIC_PRODUCT'
                    "
                    >({{ item.productQuantitySum }} products)</span
                  >
                </template>
                <template v-slot:item.checkMemo="{ item }">
                  <v-text-field
                    v-if="
                      item &&
                        item.awardVehicle &&
                        item.awardVehicle.awardVehicleType &&
                        item.awardVehicle.awardVehicleType.name == 'CHECKISSUING_CHECK'
                    "
                    v-model="formMultiPayment.checkMemo"
                    label="Check Memo"
                    :hint="'If left blank, the memo will read “Claim #' + selectedClaims[0].id + ' Payout”'"
                    persistent-hint
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogMultiPayment = false">{{
                $i18n.translate("Cancel")
              }}</v-btn>
              <v-btn color="blue darken-1" text :disabled="!validMultiPayment" @click="onMultiPayment"
                >Schedule Payment</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="dialogTemplateToUpload" max-width="500">
        <v-card>
          <v-card-title>
            <span>Upload File</span>
          </v-card-title>
          <v-card-text>
            <v-file-input v-model="templateToUpload" :loading="loading"></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-card-subtitle>
              A maximum of 1500 rows can be uploaded at one time
            </v-card-subtitle>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogTemplateToUpload = false">{{
              $i18n.translate("Cancel")
            }}</v-btn>
            <v-btn class="primary" @click="processTemplate" :loading="loading" :disabled="!templateToUpload"
              >Process File</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogTemplateProcess" max-width="500" persistent>
        <v-card>
          <v-card-title>Success</v-card-title>
          <v-card-text>
            <p>File processed successfully.</p>

            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <th width="40%" class="text-right">Amount Processed</th>
                    <td width="60%">
                      {{ creaditMemoClaimPaymentResponseForm.amountProcessed }}
                    </td>
                  </tr>
                  <tr>
                    <th width="40%" class="text-right">Amount Failed</th>
                    <td width="60%">
                      {{ creaditMemoClaimPaymentResponseForm.amountFailed }}
                    </td>
                  </tr>
                  <tr>
                    <th width="40%" class="text-right">Amount Skipped</th>
                    <td width="60%">
                      {{ creaditMemoClaimPaymentResponseForm.amountSkipped }}
                    </td>
                  </tr>
                  <tr>
                    <th width="40%" class="text-right" justify="start">Errors</th>
                    <td width="60%">
                      <ol>
                        <li
                          v-for="(error, i) in creaditMemoClaimPaymentResponseForm.errors"
                          :key="i"
                          class="text-no-wrap"
                        >
                          {{ error }}
                        </li>
                      </ol>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions
            ><v-spacer></v-spacer
            ><v-btn @click="dialogTemplateProcess = false" class="primary">OK</v-btn></v-card-actions
          >
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import DateTimePickerField from "../../gapp-components/components/fields/DateTimePickerField.vue";
import ApiError from "../../gapp-components/components/display/ApiError.vue";
import { mapGetters } from "vuex";

export default {
  components: { ApiError, DateTimePickerField },
  name: "ClaimPaymentManagement",
  metaInfo: {
    title: "Claim Payment Management"
  },
  data() {
    return {
      search: "",
      followUp: false,

      render: true,
      expanded: [],
      expandedClaimProducts: [],
      claimProductHeader: [
        {
          value: "promotionProduct.product.productKey",
          text: "Product"
        },
        {
          value: "quantity",
          text: "QTY",
          align: "center"
        },
        {
          value: "serialNumber",
          text: "Serial Number"
        },
        {
          value: "referenceNumber",
          text: "Reference Number"
        }
      ],

      dialogTemplateToUpload: false,
      dialogTemplateProcess: false,

      selectedClaimTypeCountIndex: 0,
      selectedClaimTypeCount: undefined,
      claimTypeCounts: [],

      selectedClaimStageCountIndex: 0,
      selectedClaimStageCount: undefined,
      claimStageCounts: [],

      selectedAwardVehicleCountIndex: 0,
      selectedAwardVehicleCount: undefined,
      AwardVehicleCounts: [],

      selectedOrganizationCountIndex: 0,
      selectedOrganizationCount: undefined,
      organizationCounts: [],

      loading: false,
      errors: {},
      items: [],
      nameFilter: "",
      headers: [
        {
          value: "pay",
          text: "Pay",
          sortable: false
        },
        {
          value: "claimKey",
          text: "Key",
          sortable: true
        },
        {
          value: "promotion.promotionType.name",
          text: "Type",
          sortable: true
        },
        {
          value: "promotion.name",
          text: "Promotion",
          sortable: true
        },
        {
          value: "participant.user.firstName",
          text: "Name",
          sortable: true
        },
        {
          value: "calculatedTotalClaimAmount",
          text: "Amount",
          align: "right",
          sortable: true
        },

        {
          value: "claimStage.name",
          text: "Stage",
          sortable: true
        },
        {
          value: "updatedDate",
          text: "Last Updated",
          sortable: true
        },
        {
          value: "createdDate",
          text: "Age",
          sortable: true
        },
        {
          value: "action"
        }
      ],
      total: 0,
      options: {
        itemsPerPage: 10,
        sortBy: ["updatedDate"],
        sortDesc: [true]
      },

      dialogSinglePayment: false,
      validSinglePayment: false,
      formSinglePayment: {},
      claimSinglePayment: undefined,

      dialogMultiPayment: false,
      validMultiPayment: false,
      formMultiPayment: {},
      selectedClaims: [],

      rules: {
        creditMemoNumber: [v => !!v || "Credit Memo Number is required"],
        creditMemoDate: [v => !!v || "Credit Memo Date is required"],
        checkNumber: [v => !!v || "Check Number is required"],
        achReferenceNumber: [v => !!v || "Ach Reference Number is required"],
        achDate: [v => !!v || "Ach Date is required"]
      },

      awardVehicleCounts: [],
      awardVehicleCountHeaders: [
        {
          value: "awardVehicle",
          text: "Award",
          sortable: false
        },
        {
          value: "sum",
          text: "Total Payout Amount / Products",
          align: "right",
          sortable: false
        },
        {
          value: "count",
          text: "# of Payouts",
          align: "right",
          sortable: false
        },
        {
          value: "transactionInfo",
          text: "",
          sortable: false
        },
        {
          value: "checkMemo",
          text: "",
          sortable: false
        }
      ],
      templateToUpload: null,
      creaditMemoClaimPaymentResponseForm: {},
      showSelect: false,
      extraFilters: null
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    },
    showSelect(v) {
      if (v) {
        this.extraFilters = {
          hasReferenceNumber: false,
          awardVehicleType: {
            name: "CREDIT_MEMO"
          }
        };
      } else {
        this.extraFilters = null;
      }
      this.getData();
    }
  },
  methods: {
    onSelectSinglePayment(claim) {
      this.awardVehicleCounts = [];
      this.claimSinglePayment = claim;
      this.getAwardVehicleCounts([claim]).then(() => {
        this.dialogSinglePayment = true;
      });
    },
    onSinglePayment() {
      this.loading = true;
      this.errors = {};

      let postForm = {};
      if (this.formSinglePayment.creditMemoNumber) {
        postForm.creditMemoNumber = this.formSinglePayment.creditMemoNumber;
      }
      if (this.formSinglePayment.creditMemoDate) {
        postForm.creditMemoDate = this.formSinglePayment.creditMemoDate;
      }
      if (this.formSinglePayment.checkNumber) {
        postForm.checkNumber = this.formSinglePayment.checkNumber;
      }
      if (this.formSinglePayment.checkMemo) {
        postForm.checkMemo = this.formSinglePayment.checkMemo;
      }
      if (this.formSinglePayment.achReferenceNumber) {
        postForm.achReferenceNumber = this.formSinglePayment.achReferenceNumber;
      }
      if (this.formSinglePayment.achDate) {
        postForm.achDate = this.formSinglePayment.achDate;
      }
      this.$api
        .post("/api/claims/" + this.claimSinglePayment.id + "/pay", postForm)
        .then(() => {
          this.dialogSinglePayment = false;
          this.getData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.formSinglePayment = {};
          this.loading = false;
        });
    },
    onSelectMultiPayment() {
      this.awardVehicleCounts = [];
      this.getAwardVehicleCounts(this.selectedClaims).then(() => {
        this.dialogMultiPayment = true;
      });
    },
    processTemplate() {
      this.loading = true;
      let formData = new FormData();
      formData.append("file", this.templateToUpload);
      this.$api
        .post("/api/sony/processCreditMemoTemplate", formData)
        .then(({ data }) => {
          this.creaditMemoClaimPaymentResponseForm = data;
          this.dialogTemplateToUpload = false;
          this.dialogTemplateProcess = true;
          this.loading = false;
          this.templateToUpload = undefined;
        })
        .then(() => this.getData())
        .catch(error => {
          this.loading = false;
          this.dialogTemplateToUpload = false;
          this.templateToUpload = undefined;
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    getParamsFilters(item) {
      let params = {};
      params.claim = item.id;

      if (this.search && this.search.length > 0) {
        params.keyword = this.search;
      }

      if (this.selectedClaimTypeCount) {
        params.claimTypeId = this.selectedClaimTypeCount.claimTypeId;
      }

      if (this.selectedClaimTypeCount && this.selectedClaimStageCount) {
        params.claimStageId = this.selectedClaimStageCount.claimStageId;
      }

      if (this.selectedClaimTypeCount && this.selectedOrganizationCount) {
        params.organizationId = this.selectedOrganizationCount.organizationId;
      }

      if (this.selectedClaimTypeCount && this.selectedAwardVehicleCount) {
        params.awardVehicleId = this.selectedAwardVehicleCount.awardVehicleId;
      }
      if (this.followUp) {
        params.followUp = this.followUp;
      }

      params.currentPosition = this.getCurrentElement(item);

      params.total = this.total;
      params.sortBy = this.options.sortBy[0];
      params.sortDesc = this.options.sortDesc[0];

      params.approved = true;

      return params;
    },
    toClaimDetailManagement(item) {
      let object = {};
      object.name = "cprClaimDetailManagement";
      object.query = this.getParamsFilters(item);
      return object;
    },
    onMultiPayment() {
      this.loading = true;
      this.errors = {};
      let postForm = {};
      postForm.claims = this.selectedClaims.map(claim => {
        return { id: claim.id };
      });

      if (this.formMultiPayment.creditMemoNumber) {
        postForm.creditMemoNumber = this.formMultiPayment.creditMemoNumber;
      }
      if (this.formMultiPayment.creditMemoDate) {
        postForm.creditMemoDate = this.formMultiPayment.creditMemoDate;
      }
      if (this.formMultiPayment.checkNumber) {
        postForm.checkNumber = this.formMultiPayment.checkNumber;
      }
      if (this.formMultiPayment.checkMemo != null) {
        postForm.checkMemo = this.formMultiPayment.checkMemo;
      }
      if (this.formMultiPayment.achReferenceNumber) {
        postForm.achReferenceNumber = this.formMultiPayment.achReferenceNumber;
      }
      if (this.formMultiPayment.achDate) {
        postForm.achDate = this.formMultiPayment.achDate;
      }

      this.$api
        .post("/api/claims/multi/pay", postForm)
        .then(() => {
          this.dialogMultiPayment = false;
          this.getData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
          this.formMultiPayment = {};
        });
    },
    onClear() {
      this.search = "";
      this.getData();
    },
    onSubmit() {
      this.options.page = 1;
      this.getData();
    },
    onClearSearch() {
      this.search = "";
      this.onSubmit();
    },
    onClearClaimTypeCount() {
      this.selectedClaimTypeCount = undefined;
      this.selectedClaimTypeCountIndex = 0;
      this.selectedClaimStageCount = undefined;
      this.selectedClaimStageCountIndex = 0;
      this.selectedOrganizationCount = undefined;
      this.selectedOrganizationCountIndex = 0;
      this.getData();
    },
    onSelectClaimTypeCount(claimTypeCount) {
      if (claimTypeCount && claimTypeCount.claimTypeId) {
        this.selectedClaimTypeCount = claimTypeCount;
        this.getData();
      } else {
        this.onClearClaimTypeCount();
      }
    },
    onClearClaimStageCount() {
      this.selectedClaimStageCount = undefined;
      this.selectedClaimStageCountIndex = 0;
      this.getData();
    },
    onSelectClaimStageCount(claimStageCount) {
      if (claimStageCount && claimStageCount.claimStageId) {
        this.selectedClaimStageCount = claimStageCount;
        this.getData();
      } else {
        this.onClearClaimStageCount();
      }
    },
    onClearOrganizationCount() {
      this.selectedOrganizationCount = undefined;
      this.selectedOrganizationCountIndex = 0;
      this.getData();
    },
    onSelectOrganizationCount(organizationCount) {
      if (organizationCount && organizationCount.organizationId) {
        this.selectedOrganizationCount = organizationCount;
        this.getData();
      } else {
        this.onClearOrganizationCount();
      }
    },
    onClearAwardVehicleCount() {
      this.selectedAwardVehicleCount = undefined;
      this.selectedAwardVehicleCountIndex = 0;
      this.getData();
    },
    onSelectAwardVehicleCount(awardVehicle) {
      if (awardVehicle && awardVehicle.awardVehicleId) {
        this.selectedAwardVehicleCount = awardVehicle;
        this.getData();
      } else {
        this.onClearAwardVehicleCount();
      }
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let filters = this.getFilters;
      if (this.extraFilters) {
        filters = { ...filters, ...this.extraFilters };
      }

      this.$api
        .post(
          "/api/claims/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
          if (!this.render) {
            this.renderClaimTable();
          }
        })
        .catch(() => {
          this.loading = false;
        });

      this.$api
        .post(
          "/api/claims/search/byOrganization?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.organizationCounts = data;
          this.organizationCounts.unshift({ organizationName: "All" });
        })
        .catch(error => {
          console.log(error);
        });

      this.$api
        .post("/api/claims/search/byClaimStage?size=20", filters)
        .then(({ data }) => {
          this.claimStageCounts = data;
          this.claimStageCounts.unshift({ claimStageName: "All", claimStageRank: -1 });
          this.claimStageCounts.sort((a, b) => a.claimStageRank - b.claimStageRank);
        })
        .catch(error => {
          console.log(error);
        });

      this.$api
        .post("/api/claims/search/byClaimType?size=20", filters)
        .then(({ data }) => {
          this.claimTypeCounts = data;
          this.claimTypeCounts.unshift({ claimTypeName: "All" });
        })
        .catch(error => {
          console.log(error);
        });

      this.$api
        .post("/api/claims/search/byClaimCountsByAwardVehicle?size=20", filters)
        .then(({ data }) => {
          this.awardVehicleCounts = data;
          this.awardVehicleCounts.unshift({ awardVehicleName: "All" });
        })
        .catch(error => {
          console.log(error);
        });
    },
    getAwardVehicleCounts(claims) {
      this.loading = true;
      let filters = {
        completelyPaid: false,
        claims: claims.map(claim => {
          return {
            id: claim.id
          };
        })
      };

      return this.$api
        .post("/api/payouts/search/byPayoutAwardVehicle?size=20&sort=awardVehicleName", filters)
        .then(({ data }) => {
          this.loading = false;
          this.awardVehicleCounts = data;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onDownloadClaims() {
      this.errors = {};

      let filters = this.getFilters;
      if (this.showSelect && this.selectedClaims.length > 0) {
        filters.claims = this.selectedClaims.map(elem => {
          return {
            id: elem.id
          };
        });
      }

      this.$api
        .post("/api/sony/generateCreditMemoTemplate/", filters)
        .then(({ data }) => {
          window.location.href = data.href;
          this.render = false;
          this.getData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    fetchClaimProducts(obj) {
      if (obj.item.id in this.expandedClaimProducts) {
        return;
      }
      this.loading = true;
      return this.$api
        .get("/api/claimProducts/byClaimId/" + obj.item.id)
        .then(({ data }) => {
          this.expandedClaimProducts[obj.item.id] = data.content;
          for (let elem of this.expandedClaimProducts[obj.item.id]) {
            elem.promotion = obj.item.promotion;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    renderClaimTable() {
      this.expanded = [];
      this.expandedClaimProducts = [];
      this.render = true;
    },
    showRemoveReferenceNumberBtn(claimID) {
      if (claimID in this.expandedClaimProducts) {
        return this.isGappInternalUser && this.expandedClaimProducts[claimID][0].payouts[0].referenceNumber != null;
      }
      return false;
    },
    removeReferenceNumber(claimID) {
      this.$api
        .post("/api/sony/removeReferenceNumbers/" + claimID)
        .then(() => {
          this.render = false;
          this.getData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    getCurrentElement(item) {
      return (this.options.page - 1) * this.options.itemsPerPage + this.items.indexOf(item);
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: "Admin"
      },
      { text: "Claim Payment Management" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram", "selectedParticipant"]),
    hasFilters() {
      let check = this.search && this.search.length > 0;
      return check;
    },
    isGappInternalUser() {
      return this.$privileges?.isGappInternalUser();
    },
    getFilters() {
      let filters = { submitted: true, closed: false, approved: true };
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      if (this.selectedClaimTypeCount) {
        filters.claimType = { id: this.selectedClaimTypeCount.claimTypeId };
      }

      if (this.selectedClaimTypeCount && this.selectedClaimStageCount) {
        filters.claimStage = { id: this.selectedClaimStageCount.claimStageId };
      }

      if (this.selectedClaimTypeCount && this.selectedOrganizationCount) {
        filters.organization = { id: this.selectedOrganizationCount.organizationId };
      }

      if (this.selectedClaimTypeCount && this.selectedAwardVehicleCount) {
        filters.awardVehicle = { id: this.selectedAwardVehicleCount.awardVehicleId };
      }
      if (this.followUp) {
        filters.followUp = this.followUp;
      }

      return filters;
    }
  }
};
</script>
